console.log('components');
document.addEventListener('DOMContentLoaded', function() {
  // Находим элемент с классом 'footer__block5-img'
  var footerImg = document.querySelector('.footer__block5-img');

  // Проверяем, существует ли элемент
  if (footerImg) {
      // Добавляем обработчик события 'click'
      footerImg.addEventListener('click', function() {
          // Находим элемент с ID 'header22'
          var target = document.getElementById('header22');

          // Проверяем, существует ли целевой элемент
          if (target) {
              // Прокручиваем страницу к элементу с ID 'header22'
              target.scrollIntoView({ behavior: 'smooth' });
          }
      });
  }
});
